@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
}

.phone-input {
  @apply rounded-xl border-none;
}



.box_outer .pending.small-box {
  background: #cba234;
  border-radius: 6px;
  padding: 20px 0;
}
.box_outer .pending.small-box div,.box_outer .done div,.box_outer .waiting div{
  width: 94% !important;
  margin: 0 3%;
}

.box_outer .done {
  background: #42a142;
  border-radius: 6px;
  padding: 20px 0;
}
.box_outer .waiting {
  background: #6161ff;
  border-radius: 6px;
  padding: 20px 0;
}
.auto_scroll {
  overflow: auto;
}
.box_outer .ant-row{
  width:100%;
}
.box_outer h4{
  color: #fff;
}

.task_btns {
  display: flex;
  gap: 20px;
  padding: 0;
}


.email_Flag {
  padding: 0;
  width: 20%;
  position: relative;
  margin-top: -26px;
  left: 53px;
  margin-bottom: 20px;
}

.email_Flag button , .email_Flag button:hover {
  color: #fff;
  max-width: 96px;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 0;
  height: auto;
  background: #333;
}
.company-date-modal-width {
  max-width: 550px;
  width: 100%;
}
.company-date {
  width: 48%;
} 
.company-registration {
  width: 48%;
}
.company-date-section {
  display: flex;
  justify-content: space-between;
}
.save-btn {
  background-color: green;
  padding: 8px 30px;
  font-size: 15px;
  margin-right: 10px;
}
.close-btn {
  padding: 8px 30px;
  font-size: 15px;
  margin-left: 10px;
}
.company-date-heading {
  font-size: 22px;
  font-weight: 700;
}
.highlight-line {
  margin-top: 30px;
  border-radius: 8px;
  width: 100%;
  text-align: left;
  background: #f2f2f2;
  border: none;
  font-size: 18px;
  font-weight: 400;
  padding: 16px;
  display: inline-block;
  box-shadow: 0 0 6px #e3e3e3;
}